import React from "react"
import { Helmet } from "react-helmet"
import { Wp_Seo, Wp_Page } from "../../graphql-types"

type Props = {
  data: Wp_Seo | undefined
  wp: { page: Wp_Page }
}

export const BASE_URL = "https://restaurantwollegras.nl"

export const SEO: React.FC<Props> = ({ data, wp }) => {
  const title = data?.title === "" ? wp.page.title : data?.title || undefined
  const description =
    data?.metaDesc && data?.metaDesc !== "" ? data.metaDesc : undefined

  const uri =
    data?.canonical === ""
      ? wp.page.uri.replace(/^\/|\/$/g, "")
      : data?.canonical || undefined

  const url = uri === "" ? BASE_URL : `${BASE_URL}/${uri}`

  const image =
    data?.opengraphImage?.localFile?.childImageSharp?.fixed &&
    data?.opengraphImage?.localFile?.childImageSharp.fixed !== null
      ? data?.opengraphImage?.localFile?.childImageSharp.fixed.src
      : wp.page.featuredImage?.localFile.childImageSharp.fixed !== null
      ? wp.page.featuredImage?.localFile.childImageSharp.fixed.src
      : undefined

  const twitterImage =
    data?.twitterImage?.localFile?.childImageSharp?.fixed &&
    data?.twitterImage?.localFile?.childImageSharp.fixed !== null
      ? data?.twitterImage?.localFile?.childImageSharp.fixed.src
      : wp.page.featuredImage?.localFile.childImageSharp.fixed !== null
      ? wp.page.featuredImage?.localFile.childImageSharp.fixed.src
      : undefined

  return (
    <Helmet>
      <title>{title || ""}</title>
      <meta property="og:title" content={title || ""} />
      <meta name="twitter:title" content={title || ""} />

      {description && <meta name="description" content={description || ""} />}
      {description && (
        <meta property="og:description" content={description || ""} />
      )}
      {description && (
        <meta name="twitter:description" content={description || ""} />
      )}

      <meta
        name="robots"
        content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />

      <link rel="canonical" href={url} />

      <meta property="og:locale" content="nl_NL" />
      <meta property="og:type" content="website" />

      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="Restaurant Wollegras" />
      {image && <meta property="og:image" content={`${BASE_URL}${image}`} />}
      {image && (
        <meta property="og:image:secure_url" content={`${BASE_URL}${image}`} />
      )}
      {image && <meta property="og:image:width" content="1200" />}
      {image && <meta property="og:image:height" content="630" />}

      {twitterImage && (
        <meta name="twitter:card" content="summary_large_image" />
      )}
      {twitterImage && (
        <meta name="twitter:image" content={`${BASE_URL}${twitterImage}`} />
      )}
    </Helmet>
  )
}

export default SEO
